@use "../config/" as *;
.waf-wrestlingscorecard {
    .card {
        &-item {
            @extend %relative;
            @extend %p-x-full;
            @extend %p-y-two-space;
        }
        &-header {
            flex-wrap: wrap;
            @extend %p-b-full;
            @extend %flex-c-n;
        }
        &-content {
            @extend %relative;
            @extend %m-x-full-neg;
            @extend %flex;
            &::before {
                content: '';
                width: 100%;
                height: 15%;
                z-index: var(--overlay-image);
                background: linear-gradient(to top, var(--c-blue) 25%, hsl(var(--hsl-c-blue)/0.1) 96%);
                @extend %w-100;
                @include position(null, null, 12rem, 0);
            }
        }
        &-data {
            flex-basis: calc(50% - var(--two-space));
            padding-inline: var(--full-space);
            min-height: 12rem;
            @include flex-config(flex, column-reverse, flex-end, center);
        }
        &-name,
        &-name a {
            word-break: break-all;
            @extend %uppercase;
            @extend %text-center;
            @extend %m-y-zero;
            @extend %c-white-10;
            // @extend %font-20-pb;
            @include truncate-vertical(2, 20, 20, $font-pb);
        }
        &-score {
            @extend %c-white-10;
            @extend %font-40-pb;
        }
        &-logo {
            @extend %w-100;
            @extend %h-100;
        }
    }
    .won {
        .card-score,
        .card-name,
        .card-name a {
            @extend %c-orange-10;
        }
        .card-logo {
            filter: drop-shadow(0 -10rem 4rem hsl(var(--hsl-c-orange)/0.6));
        }
    }
    .card-item {
        &.upcoming {
            .countdown-wrapper {
                @extend %d-block;
            }
            .btn-watchcenter,
            .card-score {
                @extend %d-none;
            }
        }
        &.live {
            .meta-status {
                @extend %flex-column-n-c;
            }
        }
        &.recent {
            .card-header {
                .title {
                    text-align: center;
                }
            }
        }
    }
    .btn-watchcenter {
        height: 4rem;
        @extend .btn-fill;
        @extend %m-y-full;
        @extend %font-14-pr;
        @extend %flex-c-c;
        .btn-txt {
            @extend %relative;
            @extend %p-r-half;
            &::after {
                content: '\e81d';
                font-family: $font-icon;
                @extend %p-l-half;
                @extend %font-14;
            }
        }
    }
    .countdown {
        &-wrapper {
            bottom: 7rem;
            backdrop-filter: blur(3rem);
            @extend %d-none;
            @extend %position-h-center;
            @extend %m-x-auto;
            @extend %w-70;
        }
        &-list,
        &-action {
            border: .1rem solid hsl(var(--hsl-c-white)/0.3);
            @extend %flex-c-c;
        }
        &-list {
            border-bottom: 0;
            @include border-radius(var(--half-radius) var(--half-radius) 0 0);
            @extend %p-y-full;
            @extend %c-white-bg-2;
        }
        &-item {
            @extend %c-white-10;
            @extend %flex-column-n-c;
            &:not(:last-child) {
                @extend %p-r-full;
            }
            &.seconds {
                .countdown-count {
                    &::after {
                        content: unset;
                    }
                }
            }
        }
        &-count {
            @extend %relative;
            @extend %font-40-pb;
            &::after {
                content: ':';
                right: -1.2rem;
                @extend %position-v-center;
                @extend %font-30-pb;
            }
        }
        &-label {
            @extend %c-white-7;
            @extend %font-14-pr;
        }
        &-action {
            height: 3.5rem;
            border-top: 0;
            @extend %c-white-10;
            @extend %font-14-pb;
            @extend %w-100;
            @extend %c-white-bg-3;
            @include border-radius(0 0 var(--half-radius) var(--half-radius));
            .btn-txt {
                @extend %relative;
                &::before {
                    content: '\e810';
                    font-family: $font-icon;
                    @extend %font-14;
                    @include position(null, null, null, calc(3*var(--half-space-negative)));
                }
            }
        }
    }
    .masthead-section {
        @extend %c-blue-bg-10;
        .meta {
            @extend %p-x-half;
            @extend %c-white-7;
            @extend %font-12-pr;
            &-country {
                padding-left: var(--two-space);
                @extend %relative;
                @extend %font-14;
                &::before {
                    content: '';
                    left: calc(var(--half-space) + var(--quarter-space));
                    width: .8rem;
                    height: .8rem;
                    @extend %circle-radius;
                    @extend %c-white-bg-8;
                    @extend %position-v-center;
                }
            }
            &-date {
                @extend %text-right;
            }
            // &-date,&-time {
            //     flex-basis: 50%;
            // }
            &-time {
                @extend %text-left;
            }
            &-category {
                @extend %relative;
                &::before {
                    content: '';
                    width: .1rem;
                    height: 70%;
                    left: 0;
                    @extend %c-white-bg-8;
                    @extend %position-v-center;
                }
            }
            &-status {
                top: 12rem;
                padding-inline: 0;
                @extend %d-none;
                @extend %position-h-center;
            }
            .status {
                padding: var(--quarter-space) var(--full-space) var(--quarter-space) var(--one-n-half-space);
                @extend %relative;
                @extend %full-radius;
                @extend %c-error-bg-10;
                @extend %font-12-pr;
                @extend %c-white-10;
                @extend %capitalize;
                &::before {
                    content: '';
                    background: url('/static-assets/images/svg/live-white.svg?v=#{$image-version}') no-repeat;
                    left: var(--half-space);
                    width: 1rem;
                    height: 1rem;
                    @extend %position-v-center;
                }
            }
            .time {
                @extend %p-t-half;
                @extend %c-white-10;
                @extend %font-14-pr;
            }
        }
        .title {
            @extend %c-white-10;
            @extend %font-14-pb;
        }
        .vs {
            bottom: 6rem;
            @extend %position-h-center;
            @extend %font-14-pr;
            @extend %c-white-7;
        }
        .team {
            flex-basis: 50%;
            @extend %flex-column-sb-c;
            &-logo {
                object-fit: contain;
                @extend %h-100;
            }
        }
    }
    .modal {
        &-window {
            position: fixed;
            inset: 0;
            z-index: var(--zindex-modal);
            @extend %d-none;
            @extend %c-blue-bg-8;
            &.active {
                @extend %flex-n-n;
            }
        }
        &-wrapper {
            max-width: 35rem;
            height: max-content;
            backdrop-filter: blur(3rem);
            margin-top: 10rem;
            @extend %m-x-auto;
            @extend %p-full;
            @extend %full-radius;
            @extend %c-white-bg-3;
        }
        &-content {
            @extend %text-center;
        }
        .title {
            @extend %uppercase;
            @extend %c-white-10;
            @extend %font-30-pb;
        }
        .text {
            @extend %p-y-full;
            @extend %c-white-10;
            @extend %font-14-pr;
        }
        .btn-group {
            @extend %flex-column-n-c;
        }
        .btn-site {
            height: 4.5rem;
            width: 14rem;
            @extend %font-16-pr;
        }
        .btn-fill {
            @extend %capitalize;
            @extend .btn-fill;
        }
        .btn-link {
            @extend %c-orange-10;
        }
        .btn-close {
            @extend %c-white-10;
            @extend %font-20;
            &::before {
                content: '\e804';
                font-family: $font-icon;
            }
            .btn-text {
                @extend %font-zero;
            }
        }
    }
}
@media (min-width: $tablet-min-width) {
    .waf-wrestlingscorecard {
        .btn-watchcenter {
            @include position-combo(x-center, null, var(--two-space));
        }
        .team-b {
            .card-data {
                padding-right: 0;
            }
        }
        .team-a {
            .card-data {
                padding-right: 0;
            }
            &.won {
                .card-logo {
                    filter: drop-shadow(0 -27rem 4rem hsl(var(--hsl-c-orange)/0.6));
                }
            }
        }
        .card {
            &-header {
                z-index: var(--zindex-header-player-results);
                @include position-combo(x-center, 6rem);
            }
            &-item {
                padding: 0;
                position: relative;
                min-height: 42rem;
            }
            &-data {
                flex-basis: unset;
                min-height: unset;
                padding-inline: 0;
                margin-left: auto;
                inset-block: auto;
                max-height: 15rem;
                position: absolute;
                right: 0;
                @include flex-config(null, column, space-between, null);
            }
            &-content {
                justify-content: space-between;
                margin-inline: 0;
                &::before {
                    content: unset;
                }
            }
            &-logo {
                width: 70%;
                filter: drop-shadow(0 -27rem 4rem hsl(var(--hsl-c-white)/0.3));
            }
            &-name {
                width: 18rem;
                // @include font(30);
            }
            &-score {
                @include font(50);
            }
        }
        .card-item {
            &.upcoming {
                .card-data {
                    padding-bottom: calc(4*var(--full-space));
                }
            }
            &.recent {
                .card-header {
                    width: 40%;
                    .title {
                        text-align: center;
                    }
                    .title,
                    .meta-country {
                        flex-basis: unset;
                    }
                }
            }
        }
        .masthead-section {
            .title {
                text-align: right;
                flex-basis: 50%;
                @include font(20, inherit, $font-psb);
            }
            .meta {
                @include font(16);
                &-country {
                    text-align: left;
                    flex-basis: 50%;
                    @include font(20, inherit, $font-psb);
                }
                &-date,
                &-time {
                    flex-basis: unset;
                }
                &-status {
                    top: 16rem;
                }
            }
            .team {
                flex-basis: 47%;
                position: relative;
                @include flex-config(flex, null, space-between);
                &-b {
                    flex-direction: row-reverse;
                    .card-data {
                        left: 0;
                        right: auto;
                    }
                }
                &-a {
                    flex-direction: row;
                }
            }
            .vs {
                flex-basis: 6%;
                position: unset;
                transform: unset;
                margin-inline: var(--half-space);
                padding-bottom: calc(4*var(--full-space));
                @include font(20);
                @include flex-config(flex, null, center, center);
            }
        }
        .countdown {
            &-wrapper {
                bottom: 4rem;
                width: 22%;
            }
            &-count {
                @include font(46);
            }
            &-label {
                @include font(18);
            }
            &-action {
                @include font(16);
            }
        }
        .modal {
            &-wrapper {
                max-width: 45rem;
                margin-block: auto;
            }
            .title {
                @include font(35);
            }
            .text {
                @include font(16);
            }
        }
    }
}