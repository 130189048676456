@use "../config/" as *;

.waf-wrestlingscorecard {
    .polls {
        @extend %p-full;
        @extend %m-full;
        @extend %relative;
        &::before {
            content: '';
            z-index: var(--zindex-pattern);
            @extend %half-radius;
            @extend %w-100;
            @extend %h-100;
            @extend %position-t-l;
            background: url('/static-assets/images/cssimages/listing-bg.png?v=#{$image-version}') center/ cover no-repeat;
        }
        .title {
            @extend %flex-c-c;
            @extend %w-100;
            @extend %m-y-full;
            @extend %text-center;
            @extend %c-white-10;
            @extend %font-14-pb;
        }
        .poll-item {
            &.active {
                .poll {
                    &-info {
                        background: transparent;
                        @extend %p-x-zero;
                    }
                    &-meta {
                        @extend %d-none;
                    }
                    &-thumbnail {
                        width: 5rem;
                        height: 5rem;
                    }
                }
                .progress-bar-wrap {
                    @extend %flex;
                }
            }
        }
        .poll {
            &-item {
                @extend %flex-column-n-n;
            }
            &-info {
                cursor: pointer;
                backdrop-filter: blur(15rem);
                @extend %p-full;
                @extend %half-radius;
                @extend %c-white-bg-1;
                @extend %flex;
            }
            &-thumbnail {
                width: 8rem;
                height: 8rem;
                border: .2rem solid hsl(var(--hsl-c-white)/0.2);
                @extend %circle-radius;
                @extend %c-blue-bg-10;
                .image {
                    object-fit: cover;
                    @extend %w-100;
                    @extend %h-100;
                }
            }
            &-content {
                @extend %p-l-one-n-half;
            }
            &-meta {
                @extend %flex-n-c;
                .text {
                    @extend %c-white-4;
                    @extend %font-14-pr;
                    @extend %p-l-half;
                }
            }
            &-title {
                @extend %flex-column-n-n;
                @extend %m-t-zero;
            }
        }
        .logo {
            width: 2rem;
            height: 2rem;
        }
        .name {
            @extend %d-block;
            @extend %capitalize;
            @extend %font-14-pr;
            @extend %c-white-10;
            &.lname {
                font-family: $font-pb;
            }
        }
        .radio-box-wrap {
            &.won {
                .progress-fill {
                    @extend %c-orange-bg-10;
                }
                .progress-bar-wrap {
                    @extend %flex;
                }
            }
        }
        .radio-box {
            pointer-events: none;
            @extend %position-t-l;
            @extend %w-100;
            @extend %h-100;
            &-wrap {
                @extend %relative;
                &.won {
                    .progress-fill {
                        @extend %c-orange-bg-10;
                    }
                }
            }
            input {
                pointer-events: none;
            }
        }
        .progress {
            &-bar {
                height: 3rem;
                @extend %relative;
                @extend %half-radius;
                @extend %c-white-bg-2;
                @extend %w-100;
            }
            &-fill {
                height: 3rem;
                @extend %half-radius;
                @extend %flex;
                @extend %w-50;
                @extend %c-white-bg-5;
            }
            &-percentage {
                right: var(--half-space);
                @extend %c-white-10;
                @extend %font-16-pb;
                @extend %position-v-center;
            }
        }
        .progress-bar-wrap {
            @extend %d-none;
        }
        .checkmark {
            pointer-events: none;
            visibility: hidden;
        }
        .vs {
            @extend %relative;
            @extend %flex-c-c;
            @extend %m-y-two-space;
            &::before {
                content: '';
                left: 0;
                height: .1rem;
                z-index: var(--zindex-vs);
                @extend %w-100;
                @extend %c-white-bg-2;
                @extend %position-v-center;
            }
            .text {
                width: 6rem;
                @extend %flex-c-c;
                @extend %c-blue-bg-10;
                @extend %font-18-psb;
                @extend %uppercase;
                @extend %c-white-10;
            }
        }
    }
}
@media (min-width: $tablet-min-width) {
    .waf-wrestlingscorecard {
        .polls {
            max-width: var(--container-max-width);
            margin-inline: auto;
            padding-block: var(--three-space);
            padding-inline: calc(4*var(--full-space));
            .poll-item {
                flex-wrap: wrap;
                flex-direction: row;
                .radio-box-wrap {
                    &:last-child {
                        .poll {
                            &-info {
                                flex-direction: row-reverse;
                            }
                            &-content {
                                padding-left: 0;
                                padding-right: var(--one-n-half-space);
                            }
                            &-title {
                                align-items: end;
                            }
                            &-meta {
                                flex-direction: row-reverse;
                                .text {
                                    padding-left: 0;
                                    padding-right: var(--half-space);
                                }
                            }
                        }
                        .progress-bar {
                            flex-direction: column;
                        }
                        .progress-percentage {
                            left: var(--half-space);
                        }                  
                    }
                }
                &.active {
                    justify-content: space-between;
                    .radio-box-wrap  {
                        flex-basis: 48%;
                    }
                    .poll-thumbnail {
                        width: 9rem;
                        height: 9rem;
                    }
                    .poll-info {
                        padding-top: 0;
                    }
                    .vs {
                        display: none;
                    }
                }
            }
            .poll {
                &-info {
                    padding: var(--two-space);
                }
                &-thumbnail {
                    width: 12rem;
                    height: 12rem;
                }
                &-meta {
                    .logo {
                        width: 3rem;
                        height: 3rem;
                    }
                    .text {
                        @include font(14);
                    }
                }
            }
            .name {
                @include font(24);
            }
            .radio-box-wrap {
                flex-basis: 45%;
            }
            .vs {
                margin-block: 0;
                flex-basis: 10%;
                &::before {
                    width: .1rem;
                    height: 100%;
                    @include position-combo(x-center,0);
                }
                .text {
                    height: 4rem;
                    @include font(22);
                }
            }
            .title {
                @include font(18);
            }
            .progress-percentage {
                @include font(36);
            } 
            .progress-bar {
                @include flex-config(flex,row-reverse);
            }
            .progress-bar,.progress-fill {
                height: 7rem;
            }
        }
    }
}