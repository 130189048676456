@use "../config/" as *;

.waf-wrestlingscorecard {
    @extend %p-zero;
    @extend %m-y-zero;
    @extend %m-x-half-neg;
    .waf-head {
        @extend %d-none;
    }
    .team-standing {
        @extend %p-y-two-space;
        @extend %p-x-full;
        .teams {
            @extend %flex-sb-c;
            @extend %m-b-two-space;
        }
        .team {
            &-name {
                line-height: 2.5rem;
                @extend %flex-column-n-n;
                @extend %m-b-half;
                @extend %m-t-zero;                
            }
            &-a,&-b {
                flex-basis: 50%;
            }
            &-b {
                @extend %flex-column-n-fe;
                @extend %text-right;
                .team-info {
                    flex-direction: row-reverse;
                }
                .text {
                    @extend %p-l-zero;
                    @extend %p-r-half;
                }
            }
            &-info {
                @extend %flex-fs-c;
                .text {
                    @extend %c-blue-2-10;
                    @extend %capitalize;
                }
            }
            &-flag {
                width: 2.5rem;
                height: 2.5rem;
            }
        }
        .text {
            @extend %font-14-pr;
            @extend %c-blue-9;
            @extend %p-l-half;
        }
        .name  {
            @extend %capitalize;
            @extend %c-blue-10;
            @extend %font-16;
        }
        .fname {
            font-family: $font-pr;
        }
        .lname {
            font-family: $font-pb;
        }
        .team-detail {
            @extend %flex-sb-n;
            .team {
                &-a,&-b {
                    flex-basis: 20%;
                }
                &-b {
                    .meta {
                        justify-content: flex-end;
                    }
                }
                &-labels {
                    flex-basis: 60%;
                    @extend %flex-column-n-n;
                }
                &-meta {
                    @extend %w-100;
                }
            }
            .label,.meta {
                height: 5rem;
                @extend %flex-n-c;
                @extend %relative;
                &:not(:last-child)::after {
                    content: '';
                    height: .1rem;
                    @extend %position-b-l;
                    @extend %w-100;
                    @extend %c-blue-light-bg-5;
                }
            }
            .meta {
                @extend %font-14-pb;
                @extend %c-blue-10;
            }
            .label {
                justify-content: center;
                @extend %uppercase;
                @extend %font-14-pr;
                @extend %c-blue-1-10;
            }
        }
    }
}
@media (min-width: $tablet-min-width) {
    .waf-wrestlingscorecard {
        .layout-wrapper {
            max-width: var(--content-width);
        }
        .team-standing {
            max-width: calc(var(--container-max-width) - var(--container-white-space));
            margin-inline: auto;
            padding-inline: 0;
            padding-bottom: calc(5*var(--full-space));
            .team-name {
                flex-direction: row;
            }
            .fname {
                padding-right: var(--half-space);
            }
            .name {
                @include font(24);
            }
            .text {
                @include font(18);
            }
            .team-detail {
                .label,.meta {
                    @include font(16);
                }
            }
        }
    }
}


