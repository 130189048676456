@use "../config/" as *;
@forward "../custom/progress-bar";
.waf-wrestlingscorecard {
    .team-statlisting {
        @extend %relative;
        &::before {
            content: '';
            z-index: var(--zindex-pattern);
            @extend %w-100;
            @extend %h-100;
            @extend %position-t-l;
            background: url('/static-assets/images/cssimages/listing-bg.png?v=#{$image-version}') center/ cover no-repeat;
        }
        .tab-container-wrap {
            @extend %p-y-two-space;
            @extend %p-x-full;
        }
        .title {
            @extend %uppercase;
            @extend %c-white-10;
            @extend %text-center;
            @extend %font-24-pb;
            @extend %m-b-two-space;
        }
        .team-statlisting-body {
            @extend %p-y-two-space;
            @extend %p-x-full;
            @extend %half-radius;
            @extend %c-white-bg-1;
            // @include linear-gradient(hsla(var(--hsl-c-white)/0.2), hsla(var(--hsl-c-white)/0.2));
        }
        .team-statlisting-meta {
            @extend %p-b-full;
            @extend %flex-sb-n;
            &.no-challenger {
                .meta-item {
                    flex-basis: 100%;
                    justify-content: center;
                    &.time {
                        &::after {
                            content: unset;
                        }
                    }
                }
            }
        }
        .name {
            @extend %capitalize;
            @extend %c-white-10;
            @extend %font-14-pr;
        }
        .team {
            flex-basis: 50%;
            @extend %flex-column-n-n;
            &-name {
                @extend %m-t-zero;
                @extend %flex-column-n-n;
            }
            &-b {
                @extend %text-right;
                .team-score {
                    @extend %c-white-10;
                }
            }
            &-label {
                flex-basis: 50%;
                @extend %flex-column-n-n;
                @extend %text-center;
                @extend %font-14-pb;
                .text {
                    @extend %uppercase;
                    @extend %c-white-10;
                }
                .subtext {
                    @extend %c-white-6;
                }
            }
        }
        .result {
            @extend %relative;
            @extend %p-t-full;
            @extend %flex-sb-c;
            &::before {
                content: '';
                height: .1rem;
                @extend %position-t-l;
                @extend %w-100;
                @extend %c-white-bg-2;
            }
            .team {
                flex-basis: 25%;
            }
            .team-score {
                @extend %c-orange-10;
                @extend %font-30-pb;
            }
        }
        .meta-item {
            flex-basis: 50%;
            @extend %flex-fs-c;
            @extend %capitalize;
            &.time {
                justify-content: flex-end;
                padding-right: calc(var(--half-space) + var(--quarter-space));
                @extend %relative;
                @extend %text-right;
                &::after {
                    content: '';
                    width: .16rem;
                    right: 0;
                    @extend %c-white-bg-4;
                    @extend %h-70;
                    @extend %position-v-center;
                }
                .label {
                    &::before {
                        content: '\e820';
                    }
                }
            }
            &.challenger {
                @extend %p-l-one-n-half;
                .label {
                    &::before {
                        content: '\e822';
                    }
                }
            }
            .label {
                @extend %relative;
                @extend %p-x-half;
                @extend %font-14-pr;
                @extend %c-white-4;
                &::before {
                    left: var(--full-space-negative);
                    font-family: $font-icon;
                    @extend %font-14;
                    @extend %position-v-center;
                }
            }
            .number {
                @extend %c-orange-10;
                @extend %font-18-psb;
            }
        }
        .label-wrap {
            @extend %p-b-half;
            @extend %flex-c-c;
            .label {
                @extend %capitalize;
                @extend %c-grey-10;
                // @extend %c-white-4;
                @extend %font-14-pr;
            }
        }
        .progress-view {
            @extend %flex-n-n;
        }
        .progressbar-item {
            @extend %relative;
            @extend %m-b-one-n-half;
            .team-score {
                @extend %position-t-l;
                &-b {
                    left: auto;
                    right: 0;
                }
                .text {
                    @extend %c-white-10;
                    @extend %font-14-pr;
                }
            }
        }
        .team-bar {
            flex-basis: calc(50% - var(--quarter-space));
            &-a {
                @extend %m-r-quarter;
            }
            &-b {
                @extend %m-l-quarter;
            }
        }
        .head {
            .team {
                @extend %relative;
                &-b {
                    .legend {
                        left: auto;
                        right: 0;
                        @extend %c-white-bg-10;
                    }
                    .fname {
                        @extend %p-l-zero;
                        @extend %p-r-full;
                    }
                }
            }
            .legend {
                width: .8rem;
                height: .8rem;
                @extend %c-orange-bg-10;
                @extend %circle-radius;
                @include position(.6rem, null, null, 0);
            }
            .fname {
                @extend %p-r-zero;
                @extend %p-l-full;
            }
        }
        .tooltip {
            @extend %relative;
            &:hover {
                .tooltip-text {
                    @extend %flex-column-fs-n;
                }
            }
            .title {
                @extend %m-b-half;
                @extend %text-left;
                @extend %capitalize;
                @extend %c-white-10;
                @extend %font-14-psb;
            }
            &-icon {
                cursor: pointer;
                @extend %p-l-half;
                @extend %c-white-4;
                @include font(16, 20, $font-icon);
                &::before {
                    content: '\e821';
                }
            }
            &-text {
                width: 30rem;
                z-index: var(--zindex1);
                -webkit-backdrop-filter: blur(4rem);
                backdrop-filter: blur(4rem);
                @extend %d-none;
                @extend %p-half;
                @extend %half-radius;
                @extend %c-white-bg-2;
                @extend %font-12-pr;
                @extend %c-white-7;
                @include position(var(--two-space), -10rem, null, null);
            }
        }
    }
}
@media (min-width: $tablet-min-width) {
    .waf-wrestlingscorecard {
        .team-statlisting {
            .tab-container-wrap {
                max-width: calc(var(--container-max-width) - var(--container-white-space));
                margin-inline: auto;
                padding-inline: 0;
                padding-bottom: calc(5*var(--full-space));
            }
            .team-statlisting-body {
                padding-inline: var(--three-space);
            }
            .head {
                .team {
                    &-name {
                        flex-direction: row;
                    }
                    &-a {
                        .fname {
                            padding-right: var(--half-space);
                        }
                    }
                    &-b {
                        .fname {
                            padding-right: var(--half-space);
                        }
                        .lname {
                            padding-right: var(--full-space);
                        }
                        .team-name {
                            justify-content: flex-end;
                        }
                    }
                }
            }
            .title {
                margin-bottom: var(--two-space);
            }
            .name {
                @include font(16);
            }
            .result {
                padding-bottom: 0;
                .team {
                    &-b {
                        .team-score {
                            justify-content: flex-end;
                        }
                    }
                    &-score {
                        justify-content: flex-start;
                        @include font(50);
                    }
                    &-label {
                        @include font(16);
                    }
                }
            }
            .progress-view {
                align-items: center;
            }
            .progressbar-item {
                .team-score {
                    position: unset;
                }
            }
            .team {
                &-bar {
                    flex-basis: calc(48% - var(--quarter-space));
                }
                &-score {
                    flex-basis: 4%;
                    @include flex-config(flex, null, center, center);
                }
            }
            .label-wrap {
                .label {
                    @include font(16, null, $font-pb);
                }
            }
            .meta-item {
                .label {
                    @include font(16, null, $font-pb);
                }
                .number {
                    @include font(20);
                }
            }
            .tooltip {
                &-text {
                    @include position(0, auto, null, var(--two-space));
                }
            }
        }
    }
}